import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Button,
  TextField,
  Text,
  CardList,
  Box,
  FlexGridItem,
  Tag,
  SubtleIconButton,
} from '@agendaedu/ae-web-components';

import Loader from 'components/Loader';
import Toast from 'components/Toast';
import EmptyState from 'components/EmptyState';
import ModalActions from './ModalActions';

import withFormContext from 'core/hoc/withFormContext';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import * as S from './styles';

const SubmissionTab = () => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { isLoading, submissions, filters, paginate } = useSelector(
    (state) => state.submissionAndReturn
  );

  const { fetchSubmissions } = submissionAndReturnActions;

  const [fileName, setFileName] = useState('');
  const [showActionModal, setShowActionModal] = useState(false);
  const [currentModalSubmission, setCurrentModalSubmission] = useState('');

  const handleActionModal = () => {
    setShowActionModal((oldState) => !oldState);
  };

  const displayDeleteModal = (id: string) => {
    setCurrentModalSubmission(id);
    handleActionModal();
  };

  const getActions = (status: string, file_url: string, id: string) => {
    const actions = [];

    actions.push({
      as: 'a',
      title: t('submission_and_return.tab_submission.action.view'),
      link: `/schools/imports/cnab/${id}`,
    });

    if (status === 'pending' || status === 'success') {
      actions.push({
        as: 'button',
        title: t('submission_and_return.tab_submission.action.download'),
        onClick: () => window.open(file_url),
      });
    }

    if (status === 'error') {
      actions.push({
        as: 'button',
        title: t('submission_and_return.tab_submission.action.delete'),
        onClick: () => displayDeleteModal(id),
      });
    }

    return actions;
  };

  const debouncedFetchSubmissions = _.debounce((value: string) => {
    dispatch(fetchSubmissions({ ...filters, fileName: value }, 1));
  }, 500);

  const callFetchSubmissions = useCallback(
    (value: string) => debouncedFetchSubmissions(value),
    []
  );

  const hasMoreSubmissions = () => {
    const numberOfItemsOnTheList =
      paginate.activePage * paginate.itemsCountPerPage;

    return numberOfItemsOnTheList < paginate.totalItemsCount;
  };

  const fetchMoreSubmissions = () => {
    dispatch(fetchSubmissions({ ...filters }, paginate.activePage + 1));
  };

  const handleFileNameChange = ({ target }) => {
    callFetchSubmissions(target.value);
    setFileName(target.value);
  };

  useEffect(() => {
    dispatch(fetchSubmissions({ ...filters }, paginate.activePage));
  }, [dispatch, fetchSubmissions]);

  const renderStatus = (status) => {
    const statusName = {
      preparing: t(`submission_and_return.tab_submission.status.${status}`),
      pending: t(`submission_and_return.tab_submission.status.${status}`),
      success: t(`submission_and_return.tab_submission.status.${status}`),
      error: t(`submission_and_return.tab_submission.status.${status}`),
    };

    const statusVariant = {
      preparing: 'informative',
      pending: 'warning',
      success: 'positive',
      error: 'negative',
    };

    return (
      <Tag
        name={statusName[status]}
        variant={statusVariant[status]}
        size="small"
      />
    );
  };

  const renderSubmissionsList = () => {
    if (!isLoading && !submissions?.length)
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.enrollmentEmptyStateFilterUrl}
          message={
            <Text variant="subtitle-medium-16" fontWeight={500}>
              {t('submission_and_return.tab_submission.empty_state_message')}
            </Text>
          }
        />
      );

    if (isLoading && !submissions?.length) return <Loader />;

    return (
      <CardList
        data-testid="submissions-list"
        pageStart={1}
        hasMore={!isLoading && hasMoreSubmissions()}
        loadMore={fetchMoreSubmissions}
        initialLoad={true}
        loader={<Loader />}
        mb={16}
      >
        {submissions.map((item) => {
          const {
            id,
            attributes: {
              filename,
              created_at: createdAt,
              bills_count: billsCount,
              status,
              file_url: fileUrl,
            },
          } = item;

          return (
            <React.Fragment key={id}>
              <FlexGridItem cols={{ desktopLG: 3 }}>
                <Box paddingRight={40} data-testid="filename">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t('submission_and_return.tab_submission.labels.filename')}
                  </Text>
                  <S.CustomTextName
                    variant="subtitle-medium-16"
                    color="neutral.gray1"
                  >
                    {filename}
                  </S.CustomTextName>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="created-at">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t('submission_and_return.tab_submission.labels.date')}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    {createdAt}
                  </Text>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="bills-count">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t(
                      'submission_and_return.tab_submission.labels.bills_number'
                    )}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    {billsCount}
                  </Text>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="status">
                  {renderStatus(status)}
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box position="absolute" top={20} right={16}>
                  <SubtleIconButton
                    data-testid="button-actions"
                    actions={getActions(status, fileUrl, id)}
                    align="right"
                  />
                </Box>
              </FlexGridItem>
            </React.Fragment>
          );
        })}
      </CardList>
    );
  };

  return (
    <div data-testid="submission-tab">
      <S.WrapperFilters>
        <TextField
          data-testid="input-search"
          value={fileName}
          onChange={handleFileNameChange}
          icon="search"
          placeholder={t(
            'submission_and_return.tab_submission.filters.search_file'
          )}
        />

        <Link to="/schools/imports/cnab/new">
          <Button variant="secondary" data-testid="button-import">
            {t('submission_and_return.tab_submission.filters.import_button')}
          </Button>
        </Link>
      </S.WrapperFilters>

      <S.WrapperNumberFiles>
        <Text
          data-testid="number-files"
          variant="subtitle-medium-14"
          lineHeight="md"
          mb={0}
          color={'neutral.gray2'}
        >
          {t('submission_and_return.tab_submission.amount_files', {
            totalItemsCount: paginate.totalItemsCount,
          })}
        </Text>
      </S.WrapperNumberFiles>

      <Box mt={16}>{renderSubmissionsList()}</Box>

      <ModalActions
        isOpen={showActionModal}
        onClose={handleActionModal}
        action="delete"
        id={currentModalSubmission}
      ></ModalActions>

      <Toast />
    </div>
  );
};

export default withFormContext(SubmissionTab);
