import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Toast from 'components/Toast';
import FormFullScreen from 'components/FormFullScreen';
import SubmissionForm from './SubmissionForm';

import withAppContext from 'core/hoc/withAppContext';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import { RootState } from './types';

const NewSubmission = () => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { sendingSubmission } = useSelector(
    (state: RootState) => state.submissionAndReturn
  );

  const { fetchNewSubmission } = submissionAndReturnActions;

  const handleSubmit = ({ form }) => {
    const data = {
      recipient_wallet_id: form.recipient_wallet_id,
      file: form.file[0].signed_id,
    };

    dispatch(fetchNewSubmission(data));
  };

  return (
    <>
      <FormFullScreen
        action="new"
        steps={[SubmissionForm]}
        form={{}}
        formMeta={{
          nextStepRequiredFields: ['recipient_wallet_id', 'file'],
        }}
        isSubmitting={sendingSubmission}
        onSubmit={handleSubmit}
        backTo={`/schools/imports/cnab`}
        titlePage={t('submission_and_return.new_submission.title_page')}
        titleModal={t(
          'submission_and_return.new_submission.title_modal_discard'
        )}
        contentModal={t(
          'submission_and_return.new_submission.content_modal_discard'
        )}
      />
      <Toast />
    </>
  );
};

export default withAppContext(NewSubmission);
