import React, { useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import { Box, Text, Grid, Icon } from '@agendaedu/ae-web-components';
import Accordion from 'components/Accordion';
import Avatar from 'components/Avatar';
import Currency from 'components/Currency';
import Loader from 'components/Loader';
import withWindowSize from 'core/hoc/withWindowSize';
import Skeleton from './Skeleton';
import ExtractDetailsModal from './ExtractDetailsModal';

import actionAvailabilities from 'store/edupay/availabilities/actions';

import './style.scss';

import * as S from './styles';

function AvailabilityAccordion(props) {
  const { t } = useTranslation(['payments', 'common']);

  const [scrollRef, setScrollRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    date,
    income,
    skeletonSize,
    windowSize,
    fetchPayments,
    availability,
    showAvailability,
    toggleModal,
    hasMore,
    payments,
    payment,
    modalLoading,
    modalOpen,
    activeTableAvailability,
    numberOfPayments,
  } = props;

  const { page } = useSelector((state) => state.availabilities);

  const typeQuery =
    activeTableAvailability == 'guaranteed_monthly_fee'
      ? 'guaranteed_monthly_fee'
      : 'school';

  const fetchAvailabilities = () => {
    if (!hasMore || availability.isLoading) return;
    fetchPayments(date, page + 1, typeQuery, hasMore);
  };

  const onChange = useCallback(
    (open) => {
      setIsOpen((oldIsOpen) => !oldIsOpen);
      if (open.length) {
        fetchPayments(date, 1, typeQuery);
      }
    },
    [typeQuery, date, fetchPayments]
  );

  const toggleAvailabilityModal = useCallback(
    (element) => {
      toggleModal(date);

      if (!modalOpen) {
        showAvailability(date, element.id);
      }
    },
    [modalOpen, date, toggleModal, showAvailability]
  );

  const renderAccordionHeader = () => (
    <S.WrapperHeaderAccordion>
      <Grid item gridArea="date">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.accordion_extract.date')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {date.formatted}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="amount">
        <Box>
          <Text
            variant="subtitle-medium-12"
            color="neutral.gray2"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {t('wallet.accordion_extract.number_of_payments')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
          >
            {numberOfPayments}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="entry">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t(`wallet.accordion_extract.${income < 0 ? 'refund' : 'entry'}`)}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color={
              income < 0 ? 'context.danger.default' : 'context.success.default'
            }
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <Currency value={income} />
          </Text>
        </Box>
      </Grid>

      <S.WrapperIcon item gridArea="icon">
        <Icon
          color="neutral.gray1"
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          size="sm"
        />
      </S.WrapperIcon>
    </S.WrapperHeaderAccordion>
  );

  const renderAvailability = (element) => {
    const paymentMethods = {
      billet: (
        <>
          <Icon color="neutral.gray1" name="bar-code" size="md" />
          Boleto
        </>
      ),
      credit_card: (
        <>
          <Icon color="neutral.gray1" name="credit-card" size="md" />
          Cartão - À vista
        </>
      ),
      pix: (
        <>
          <Icon color="neutral.gray1" name="pix" size="md" />
          PIX
        </>
      ),
      credit_card_parcel: (
        <>
          <Icon color="neutral.gray1" name="credit-card" size="md" />
          {element.attributes.provider === 'bemobi'
            ? `Cartão - Parcelado em ${element.attributes.total_installments}x`
            : `Cartão - Parcela ${element.attributes.installment}`}
        </>
      ),
    };

    return (
      <S.WrapperBodyAccordion onClick={() => toggleAvailabilityModal(element)}>
        <S.WrapperStudent item gridArea="student">
          <Avatar
            user={
              element.attributes.student_profile
                ? element.attributes.student_profile.data
                : element.attributes.responsible_profile.data
            }
          />
          <S.BoxWithoutOverflow>
            <Text variant="subtitle-medium-12" color="neutral.gray2">
              {t(
                `wallet.accordion_extract.${
                  element.attributes.student_profile ? 'student' : 'responsible'
                }`
              )}
            </Text>
            <Text
              variant="subtitle-medium-16"
              mt="sm"
              mb="xs4"
              color="neutral.gray1"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {element.attributes.student_profile
                ? element.attributes.student_profile.data.attributes.name
                : element.attributes.responsible_profile.data.attributes.name}
            </Text>
          </S.BoxWithoutOverflow>
        </S.WrapperStudent>

        <Grid item gridArea="bill">
          <S.BoxWithoutOverflow>
            <Text
              variant="subtitle-medium-12"
              color="neutral.gray2"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {t('wallet.accordion_extract.bill_name')}
            </Text>
            <Text
              variant="subtitle-medium-16"
              mt="sm"
              mb="xs4"
              color="neutral.gray1"
            >
              {element.attributes.bill_title}
            </Text>
          </S.BoxWithoutOverflow>
        </Grid>

        <Grid item gridArea="method">
          <S.BoxWithoutOverflow>
            <Text
              variant="subtitle-medium-12"
              color="neutral.gray2"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {t('wallet.accordion_extract.payment_method')}
            </Text>
            <S.PaymentMethod
              variant="subtitle-medium-16"
              mt="sm"
              mb="xs4"
              color="neutral.gray1"
            >
              {paymentMethods[element.attributes.payment_method]}
            </S.PaymentMethod>
          </S.BoxWithoutOverflow>
        </Grid>

        <Grid item gridArea="entry">
          <S.BoxWithoutOverflow>
            <Text variant="subtitle-medium-12" color="neutral.gray2">
              {t(
                `wallet.accordion_extract.${
                  element.attributes.status === 'refunded' ? 'refund' : 'entry'
                }`
              )}
            </Text>
            <Text
              variant="subtitle-medium-16"
              mt="sm"
              mb="xs4"
              color={
                element.attributes.status === 'refunded'
                  ? 'context.danger.default'
                  : 'context.success.default'
              }
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              <Currency value={element.attributes.amount_cents} />
            </Text>
          </S.BoxWithoutOverflow>
        </Grid>
      </S.WrapperBodyAccordion>
    );
  };

  const renderSkeleton = () => {
    const viewport = windowSize >= 768 ? 'desktop' : 'mobile';

    return <Skeleton viewport={viewport} />;
  };

  const renderAccordionBody = () => {
    const skeleton = () =>
      Array(skeletonSize)
        .fill()
        .map((_) => renderSkeleton());

    const content = () =>
      payments.map((element) => renderAvailability(element));

    return (
      <div className="availabilities" ref={(ref) => setScrollRef(ref)}>
        {availability && availability.isLoading && !hasMore ? (
          skeleton()
        ) : (
          <InfiniteScroll
            loadMore={fetchAvailabilities}
            pageStart={1}
            hasMore={hasMore}
            useWindow={false}
            getScrollParent={() => scrollRef}
            loader={<Loader key="availability-loader" />}
            initialLoad={false}
            threshold={windowSize >= 768 ? 56 : 117}
          >
            {content()}
          </InfiniteScroll>
        )}
      </div>
    );
  };

  return (
    <S.WrapperAccordion className="AvailabilityAccordion">
      <Accordion
        header={renderAccordionHeader()}
        body={renderAccordionBody()}
        onChange={onChange}
        allowZeroExpanded
      />
      <ExtractDetailsModal
        isOpen={modalOpen}
        toggleModal={toggleAvailabilityModal}
        modalLoading={modalLoading}
        payment={payment}
        selectedFilter={props?.selectedFilter}
      />
    </S.WrapperAccordion>
  );
}

AvailabilityAccordion.propTypes = {
  date: PropTypes.shape({
    formatted: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  income: PropTypes.number.isRequired,
  skeletonSize: PropTypes.number,
  windowSize: PropTypes.number.isRequired,
  activeTableAvailability: PropTypes.string,
  fetchPayments: PropTypes.func,
  availability: PropTypes.array,
  showAvailability: PropTypes.bool,
  toggleModal: PropTypes.func,
  hasMore: PropTypes.bool,
  payments: PropTypes.array,
  payment: PropTypes.object,
  modalLoading: PropTypes.bool,
  modalOpen: PropTypes.bool,
  numberOfPayments: PropTypes.number,
  selectedFilter: PropTypes.string,
};

AvailabilityAccordion.defaultProps = {
  variation: 'success',
  skeletonSize: 3,
};

const mapStateToProps = ({ availabilities }, { date }) => {
  const availability = availabilities[date.value];

  const hasMore =
    availability && availability.payments
      ? availability.payments.length < availability.totalItemsCount
      : false;

  const payments =
    availability && availability.payments ? availability.payments : [];

  const payment =
    availability && availability.payment ? availability.payment : null;

  const modalLoading =
    availability && availability.modalLoading
      ? availability.modalLoading
      : false;

  const modalOpen =
    availability && availability.modalOpen ? availability.modalOpen : false;

  return {
    availability,
    hasMore,
    payments,
    modalLoading,
    modalOpen,
    payment,
  };
};

export default connect(
  mapStateToProps,
  actionAvailabilities
)(withWindowSize(AvailabilityAccordion));
