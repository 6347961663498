import styled from 'styled-components';
import { Text } from '@agendaedu/ae-web-components';

export const WrapperFilters = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperNumberFiles = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomTextName = styled(Text)`
  word-break: break-all;
  overflow-wrap: break-word;
`;
