import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import webCheckoutActions from 'store/edupay/webCheckout/actions';
import { trackEvent } from 'config/amplitude';

// TODO: This component is generic so it shouldn't live inside screens/DynamicModule.
import ResponsiveIframe from 'screens/DynamicModule/ResponsiveIframe';
import Toast from 'components/Toast';

const WebCheckout = (props) => {
  const dispatch = useDispatch();
  const { buildToast, startPaymentProcessing } = webCheckoutActions;

  const { t } = useTranslation('payments');

  const [postMessage, setPostMessage] = useState({});

  const currentWebCheckoutId = () =>
    window.location.pathname.split('/').slice(-1)[0];

  const listenToPostMessage = () => {
    window.addEventListener(
      'message',
      (event) => {
        setPostMessage(event.data);
      },
      false
    );
  };

  useEffect(() => {
    trackEvent('web-checkout-view');
    listenToPostMessage();
  }, []);

  const defineErrorMessage = (errorDetail) => {
    const errorMap = {
      'maximum attempts allowed reached':
        'common.errors.maximum_attempts_reached',
      'Autorizacao negada': 'common.errors.not_authorized',
      'CONTATE A CENTRAL DO SEU CARTÃO': 'common.errors.not_authorized',
      'CONTATE A CENTRAL DO SEU CARTÃO - NÃO TENTE NOVAMENTE':
        'common.errors.not_authorized',
      'TRANSAÇÃO NÃO PERMITIDA PARA O CARTAO - NAO TENTE NOVAMENTE':
        'common.errors.not_authorized',
      'suspected fraud': 'common.errors.suspected_fraud',
      'CARTÃO BLOQUEADO': 'common.errors.blocked_card',
      'Pagamento já realizado': 'common.errors.order_already_paid',
      'There are paid invoices on this order':
        'common.errors.order_already_paid',
      'SALDO INSUFICIENTE': 'common.errors.insufficient_balance',
      'CARTAO VENCIDO OU DATA EXPIRACAO INVALIDA': 'common.errors.expired_card',
      'DATA DE EXPIRAÇÃO INVÁLIDA': 'common.errors.expired_card',
    };

    return t(errorMap[errorDetail] || 'common.errors.generic_error');
  };

  const handlePostMessage = async (postMessage) => {
    if (postMessage.type === 'ok') {
      const params = {
        installments: postMessage.transaction.installments,
        payment_method: 'credit_card',
        external_id: postMessage.correlationId,
        transaction_id: postMessage?.transaction?.id,
      };
      await dispatch(startPaymentProcessing(currentWebCheckoutId(), params));
      setTimeout(() => window.open('/', '_self'), 6000);
    } else if (
      postMessage.type === 'payment-error' ||
      postMessage.type === 'internal-server-error'
    ) {
      const message = defineErrorMessage(postMessage.detail);
      dispatch(buildToast(message, 'error'));
    }
  };

  useEffect(() => {
    handlePostMessage(postMessage);
  }, [postMessage]);

  return (
    <>
      <div className="wrapper">
        <ResponsiveIframe src={props.iframeLink} ratio="10:9" />
      </div>
      <Toast />
    </>
  );
};

export default WebCheckout;
