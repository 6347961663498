import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Text } from '@agendaedu/ae-web-components';

export const WrapperFilters = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperNumberFiles = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: ${space.xl};
  `}
`;

export const CustomTextName = styled(Text)`
  word-break: break-all;
  overflow-wrap: break-word;
`;
