import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const StatusWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: ${space.sm};
    row-gap: 2rem;
  `}
`;

export const InfosWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: ${space.xl};
    row-gap: 2rem;
    margin-bottom: ${space.xl};
  `}
`;

export const InfoIconWrapper = styled.div`
  ${({ theme: { space, primaryColor, border } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs};

    span.icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: ${colorWithAlpha(`${primaryColor}`, 0.1)};
      border-radius: ${border.radius.md};
      height: ${space.xl2};
      width: ${space.xl2};
    }

    div.label-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${space.xs};
    }
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: -8px 0 ${space.xl5} !important;
  `}
`;

export const HistoryWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs};
  `}
`;

export const CustomSeparator = styled.hr`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.sm} 0;
  `}
`;

export const FooterButtonsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${space.xl};
  `}
`;

export const BillsPreviewTable = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    max-height: 287px;
    overflow-x: auto;
    border-radius: ${border.radius.md};
    margin-bottom: ${space.xl2};

    table {
      width: 100%;
      border-collapse: collapse;
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};

      thead {
        background-color: ${colors.neutral.gray6};
      }

      th {
        padding: ${space.sm};
        text-align: left;
        color: ${colors.neutral.gray1};
        border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
      }

      td {
        padding: ${space.xs} ${space.sm};
        border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
      }
    }
  `}
`;
