import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  Icon,
  DefaultThemeProps,
  Tag,
  Alert,
  Text,
  Avatar,
  Button,
  CardList,
  Box,
  FlexGridItem,
} from '@agendaedu/ae-web-components';

import EmptyState from 'components/EmptyState';
import Currency from 'components/Currency';
import ModalActions from '../../ModalActions';

import {
  SUBSMISSION_STATUS,
  ALLOWED_PAYMENT_METHODS,
  IMAGES_PAYMENT_URL,
} from 'core/constants/index';

import { SubmissionDetailsProps } from './types';

import * as S from './styles';

const InformationsTab = ({ submission }: SubmissionDetailsProps) => {
  const {
    data: {
      id,
      attributes: {
        status,
        filename,
        created_at: createdAt,
        allowed_payment_methods: allowedPaymentMethods,
        bills_preview: billsPreview,
        bills_count: billsCount,
        errors,
      },
    },
    history: {
      included: [
        {
          attributes: {
            avatar_color: avatarColor,
            name_initials: nameInitials,
            name,
          },
        },
      ],
    },
    meta: { wallet_name: walletName },
  } = submission;
  const { t } = useTranslation(['payments', 'common']);
  const { colors } = useTheme() as DefaultThemeProps;

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModal, setActionModal] = useState(null);

  const tBase = useCallback(
    (key: string) =>
      t(`submission_and_return.submission_details.tab_informations.${key}`),
    [t]
  );

  const handleActionModal = (action = null) => {
    setActionModal(action);
    setShowActionModal((oldState) => !oldState);
  };

  const renderPreparingSubmission = () => {
    return (
      <S.EmptyStateWrapper>
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.preparing_submission}
          message={
            <Text variant="subtitle-medium-16" fontWeight={500} mt={-30}>
              {tBase(`empty_state_message`)}
            </Text>
          }
        />
      </S.EmptyStateWrapper>
    );
  };

  const renderBillsPreviewTable = () => {
    const headers = ['external_id', 'payer_name', 'value', 'occurrence_type'];

    const renderTitle = (title: string) => (
      <Text
        variant="title-bold-12"
        mb={0}
        color="neutral.gray1"
        fontWeight={700}
      >
        {title}
      </Text>
    );

    const renderValue = (value: string | React.ReactElement) => (
      <Text variant="body-regular-14" mb={0} color="neutral.black">
        {value}
      </Text>
    );

    return (
      <S.BillsPreviewTable>
        <table>
          <thead>
            <tr>
              {headers.map((key) => (
                <th key={key}>
                  {renderTitle(tBase(`bills_preview_table.headers.${key}`))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {billsPreview.map((bill) => (
              <tr key={bill.external_id}>
                <th>{renderValue(bill.external_id)}</th>
                <th>{renderValue(bill.payer_name)}</th>
                <th>{renderValue(<Currency value={bill.value} />)}</th>
                <th>
                  {renderValue(
                    tBase(`bills_preview_table.types.${bill.occurrence_type}`)
                  )}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </S.BillsPreviewTable>
    );
  };

  const renderErrors = () => {
    return (
      <CardList mb={16} pageStart={1}>
        {errors.map((error, index) => {
          const { name, position } = error;

          return (
            <React.Fragment key={index}>
              <FlexGridItem>
                <Box paddingRight={40}>
                  <Tag
                    name={tBase(`errors_table.errors.${name}.name`)}
                    variant="negative"
                    size="small"
                  />
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40}>
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {tBase(`errors_table.line`)}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    {position}
                  </Text>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40}>
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {tBase(`errors_table.message`)}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    {tBase(`errors_table.errors.${name}.message`)}
                  </Text>
                </Box>
              </FlexGridItem>
            </React.Fragment>
          );
        })}
      </CardList>
    );
  };

  const renderSubmissionInfos = () => {
    if (status === 'preparing') {
      return renderPreparingSubmission();
    }

    if (status === 'pending' || status === 'success') {
      return renderBillsPreviewTable();
    }

    if (status === 'error') {
      return renderErrors();
    }
  };

  return (
    <>
      <S.StatusWrapper>
        <Tag
          name={SUBSMISSION_STATUS[status]?.label}
          variant={SUBSMISSION_STATUS[status]?.variant}
          mb={16}
        />
      </S.StatusWrapper>

      {status !== 'success' && (
        <Alert
          variant={status === 'error' ? 'negative' : 'informative'}
          mb={32}
        >
          {tBase(`alert_message_${status}`)}
        </Alert>
      )}

      <Text variant="headline-h2-bold-24" mb={24} color="neutral.black">
        {filename}
      </Text>

      <S.InfosWrapper>
        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <Icon
              name="calendar"
              size="md"
              color={colors.brand.primary.default}
            />
          </span>

          <div className="label-wrapper">
            <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
              {tBase(`import_date`)}
            </Text>

            <Text variant="label-regular-16" color="neutral.black" mb={0}>
              {createdAt}
            </Text>
          </div>
        </S.InfoIconWrapper>

        {status !== 'preparing' && (
          <S.InfoIconWrapper>
            <span className="icon-wrapper">
              <Icon
                name="bar-code"
                size="md"
                color={colors.brand.primary.default}
              />
            </span>

            <div className="label-wrapper">
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase(`bills_quantity`)}
              </Text>

              <Text variant="label-regular-16" color="neutral.black" mb={0}>
                {billsCount}
              </Text>
            </div>
          </S.InfoIconWrapper>
        )}

        {allowedPaymentMethods?.length > 0 && (
          <S.InfoIconWrapper>
            <span className="icon-wrapper">
              <Icon
                name="money-cycle"
                size="md"
                color={colors.brand.primary.default}
              />
            </span>

            <div className="label-wrapper">
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase(`payment_method`)}
              </Text>

              <Text variant="label-regular-16" color="neutral.black" mb={0}>
                {allowedPaymentMethods
                  .map(
                    (paymentMethod) => ALLOWED_PAYMENT_METHODS[paymentMethod]
                  )
                  .join(', ')}
              </Text>
            </div>
          </S.InfoIconWrapper>
        )}

        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <Icon
              name="wallet"
              size="md"
              color={colors.brand.primary.default}
            />
          </span>

          <div className="label-wrapper">
            <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
              {tBase(`wallet`)}
            </Text>

            <Text variant="label-regular-16" color="neutral.black" mb={0}>
              {walletName}
            </Text>
          </div>
        </S.InfoIconWrapper>
      </S.InfosWrapper>

      {renderSubmissionInfos()}

      <S.CustomSeparator />

      <S.HistoryWrapper>
        <Avatar
          avatarColor={avatarColor}
          nameInitials={nameInitials}
          size="sm"
        />

        <div>
          <Text
            variant="title-bold-12"
            color="neutral.gray1"
            mb={8}
            fontWeight={700}
          >
            {t(
              'submission_and_return.submission_details.tab_informations.history',
              { name: name }
            )}
          </Text>
          <Text variant="subtitle-medium-12" color="neutral.gray2" mb={0}>
            {createdAt}
          </Text>
        </div>
      </S.HistoryWrapper>

      <S.CustomSeparator />

      {status !== 'success' && status !== 'error' && (
        <S.FooterButtonsWrapper>
          <Button
            data-testid="approve-button"
            variant="primary"
            disabled={status === 'preparing'}
            onClick={() => handleActionModal('approve')}
          >
            {tBase(`button_approve`)}
          </Button>

          <Button
            data-testid="delete-button"
            variant="secondary"
            icon="trash-bin"
            isOnlyIcon
            contextVariant="danger"
            onClick={() => handleActionModal('delete')}
          />
        </S.FooterButtonsWrapper>
      )}

      <ModalActions
        id={id}
        isOpen={showActionModal}
        onClose={handleActionModal}
        action={actionModal}
      ></ModalActions>
    </>
  );
};

export default InformationsTab;
