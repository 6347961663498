import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Text } from '@agendaedu/ae-web-components';

import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import Loader from 'components/Loader';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import Toast from 'components/Toast';
import InformationsTab from './InformationsTab';

import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import { SubmissionDetailsProps } from './types';

const SubmissionDetails = ({
  match: {
    params: { id },
  },
}: SubmissionDetailsProps) => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { isLoading, currentSubmission } = useSelector(
    (state) => state.submissionAndReturn
  );

  const { fetchSubmissionDetails } = submissionAndReturnActions;

  useEffect(() => {
    dispatch(fetchSubmissionDetails(id));
  }, [dispatch, fetchSubmissionDetails, id]);

  return (
    <PageContainer
      title={t('submission_and_return.submission_details.title_page')}
      className="SubmissionDetails"
      breadcrumb={
        <Breadcrumb
          title={t('submission_and_return.submission_details.title_breadcrumb')}
          path="/schools/imports/cnab"
        />
      }
    >
      <InternalErrorBoundary>
        <Loader isLoading={isLoading}>
          <Tabs defaultIndex={0}>
            <Tab
              title={t(
                'submission_and_return.submission_details.tabs.tab_informations_title'
              )}
            >
              {currentSubmission ? (
                <InformationsTab submission={currentSubmission} />
              ) : (
                <Text
                  variant="subtitle-medium-16"
                  fontWeight={500}
                  color="neutral.black"
                >
                  {t(
                    'submission_and_return.submission_details.submission_not_found'
                  )}
                </Text>
              )}
            </Tab>
          </Tabs>
        </Loader>
      </InternalErrorBoundary>

      <Toast />
    </PageContainer>
  );
};

export default SubmissionDetails;
