import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import WalletInformation from 'components/Payments/WalletInformation';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import SubmissionTab from './SubmissionTab';
import ReturnTab from './ReturnTab';
import BillsTab from './BillsTab';

import withAppContext from 'core/hoc/withAppContext';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import * as S from './styles';

const SubmissionAndReturnContainer = () => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { clearFiltersAndPaginate } = submissionAndReturnActions;

  const handleSelectTab = useCallback(() => {
    dispatch(clearFiltersAndPaginate());
  }, [dispatch, clearFiltersAndPaginate]);

  return (
    <S.SubmissionAndReturnWrapper
      title={t('submission_and_return.title')}
      wallet={<WalletInformation />}
    >
      <Tabs defaultIndex={0} onSelect={handleSelectTab}>
        <Tab title={t('submission_and_return.tabs.tab_submission_title')}>
          <SubmissionTab></SubmissionTab>
        </Tab>

        <Tab title={t('submission_and_return.tabs.tab_return_title')}>
          <ReturnTab></ReturnTab>
        </Tab>

        <Tab title={t('submission_and_return.tabs.tab_bills_title')}>
          <BillsTab></BillsTab>
        </Tab>
      </Tabs>
    </S.SubmissionAndReturnWrapper>
  );
};

export default withAppContext(SubmissionAndReturnContainer);
