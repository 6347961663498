import React, { useEffect, useRef } from 'react';

import { ScrollEndHandlerProps } from './types';

export const ScrollEndHandler: React.FC<ScrollEndHandlerProps> = ({
  onScrollEnd,
  children,
  scrollableTarget,
  loader,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateHeight = () => {
      const parent = document.getElementById(scrollableTarget);
      if (parent && containerRef.current) {
        containerRef.current.style.height = `${parent.clientHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [scrollableTarget]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 100) {
        onScrollEnd();
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [onScrollEnd]);

  return (
    <div
      ref={containerRef}
      style={{
        height: 'auto',
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      {children}
      {loader}
    </div>
  );
};
