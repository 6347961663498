import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import { goBack } from 'connected-react-router';
import i18n from 'config/i18n';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

import { fetchApi, patchApi, postApi, deleteApi } from 'core/utils/api';
import {
  ERROR_MESSAGES_APPROVE_SUBMISSION,
  ERROR_MESSAGES_EXPORT_RETURN,
} from 'core/constants/index';

import actions from './actions';

export function* fetchSubmissionsSagas({ filters, page }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const response = yield call(fetchApi, `/${dataArea}/imports/cnab.json`, {
      params: {
        recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
        filename: filters?.fileName,
        page,
        per_page: 15,
      },
    });

    yield put({
      type: actions.FETCH_SUCCESS_SUBMISSIONS,
      data: response.data,
      filters,
      activePage: page,
      itemsCountPerPage: response.itemsCountPerPage,
      totalItemsCount: response.totalItemsCount,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.error_submission'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchNewSubmissionSagas({ data }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(postApi, `/${dataArea}/imports/cnab`, data);

    yield put(goBack());

    yield put({
      type: actions.FETCH_SUCCESS_NEW_SUBMISSION,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.success_new_submission'
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error: error?.data?.errors,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.error_new_submission'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSubmissionDetailsSagas({ id }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const response = yield call(
      fetchApi,
      `/${dataArea}/imports/cnab/${id}.json`
    );

    yield put({
      type: actions.FETCH_SUCCESS_SUBMISSION_DETAILS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.error_details_submission'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchApproveSubmissionSagas({ id }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(patchApi, `/${dataArea}/imports/cnab/${id}/approve`);

    yield put({
      type: actions.FETCH_SUCCESS_APPROVE_SUBMISSION,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.success_approve_submission'
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    const detail = error[0]?.detail || '';
    const errorMessageKey = Object.keys(ERROR_MESSAGES_APPROVE_SUBMISSION).find(
      (key) => detail.includes(key)
    );
    const errorMessageTranslationKey = errorMessageKey
      ? `payments:submission_and_return.tab_submission.toast.errors_approve_submission.${ERROR_MESSAGES_APPROVE_SUBMISSION[errorMessageKey]}`
      : 'payments:submission_and_return.tab_submission.toast.errors_approve_submission.no_method';

    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t(errorMessageTranslationKey),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchDeleteSubmissionSagas({ id }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/imports/cnab/${id}`);

    yield put({
      type: actions.FETCH_SUCCESS_DELETE_SUBMISSION,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.success_delete_submission'
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_submission.toast.error_delete_submission'
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchReturnsSagas({ filters, page }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const response = yield call(fetchApi, `/${dataArea}/exports/cnab.json`, {
      params: {
        recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
        filename: filters?.fileName,
        page,
        per_page: 15,
      },
    });

    yield put({
      type: actions.FETCH_SUCCESS_RETURNS,
      data: response.data,
      filters,
      activePage: page,
      itemsCountPerPage: response.itemsCountPerPage,
      totalItemsCount: response.totalItemsCount,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t('payments:submission_and_return.tab_return.toast.error_return'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchExportReturnSagas() {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const response = yield call(postApi, `/${dataArea}/exports/cnab`, {
      recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
    });

    window.open(response?.data?.attributes?.filename);

    yield put({
      type: actions.FETCH_SUCCESS_EXPORT_RETURN,
      toast: buildToast(
        i18n.t(
          'payments:submission_and_return.tab_return.toast.success_export_return'
        ),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    const detail = error?.data?.errors[0]?.detail || '';
    const errorMessageKey = Object.keys(ERROR_MESSAGES_EXPORT_RETURN).find(
      (key) => detail.includes(key)
    );
    const errorMessageTranslationKey = errorMessageKey
      ? `payments:submission_and_return.tab_return.toast.errors_export_return.${ERROR_MESSAGES_EXPORT_RETURN[errorMessageKey]}`
      : 'payments:submission_and_return.tab_return.toast.errors_export_return.no_method';

    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t(errorMessageTranslationKey),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchBillsSagas({ filters, page }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentWallet } = yield select((state) => state.wallets);

    const response = yield call(fetchApi, `/${dataArea}/bills`, {
      params: {
        recipient_wallet_id: currentWallet?.attributes?.recipient_wallet_id,
        title: filters?.title,
        page,
        per_page: 15,
      },
    });

    yield put({
      type: actions.FETCH_SUCCESS_BILLS,
      data: response.data,
      filters,
      activePage: page,
      itemsCountPerPage: response.itemsCountPerPage,
      totalItemsCount: response.totalItemsCount,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        i18n.t('payments:submission_and_return.tab_return.toast.error_bills'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* buildToastMessage({ message, typeToast }) {
  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, typeToast, toastCss(typeToast)),
  });
}

function* submissionAndReturnSagas() {
  yield all([
    takeLatest(actions.FETCH_REQUEST_SUBMISSIONS, fetchSubmissionsSagas),
    takeLatest(actions.FETCH_REQUEST_NEW_SUBMISSION, fetchNewSubmissionSagas),
    takeLatest(
      actions.FETCH_REQUEST_SUBMISSION_DETAILS,
      fetchSubmissionDetailsSagas
    ),
    takeLatest(
      actions.FETCH_REQUEST_APPROVE_SUBMISSION,
      fetchApproveSubmissionSagas
    ),
    takeLatest(
      actions.FETCH_REQUEST_DELETE_SUBMISSION,
      fetchDeleteSubmissionSagas
    ),
    takeLatest(actions.FETCH_REQUEST_RETURNS, fetchReturnsSagas),
    takeLatest(actions.FETCH_REQUEST_EXPORT_RETURN, fetchExportReturnSagas),
    takeLatest(actions.FETCH_REQUEST_BILLS, fetchBillsSagas),
    takeLatest(actions.BUILD_TOAST, buildToastMessage),
  ]);
}

export default submissionAndReturnSagas;
