import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Text, Modal } from '@agendaedu/ae-web-components';

import withAppContext from 'core/hoc/withAppContext';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import { ModalActionsProps } from './types';

import * as S from './styles';

const ModalActions = ({ isOpen, action, id, onClose }: ModalActionsProps) => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { fetchApproveSubmission, fetchDeleteSubmission } =
    submissionAndReturnActions;

  const handleSubmission = () => {
    if (action === 'approve') {
      dispatch(fetchApproveSubmission(id));
    }
    if (action === 'delete') {
      dispatch(fetchDeleteSubmission(id));
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Text variant="title-bold-20" color="black" fontWeight={700} mb={0}>
          {t(
            `submission_and_return.tab_submission.modal_actions.title_${action}`
          )}
        </Text>
      }
    >
      <S.ContentWrapper>
        <Text
          variant="body-regular-16"
          color="neutral.gray1"
          lineHeight="lg"
          paddingX={4}
          mt={16}
          mb={24}
        >
          {t(
            `submission_and_return.tab_submission.modal_actions.description_${action}`
          )}
        </Text>

        <S.FooterWrapper>
          <Button testId="back-btn" variant="secondary" onClick={onClose}>
            {t(
              `submission_and_return.tab_submission.modal_actions.button_back`
            )}
          </Button>

          <Button
            testId="next-btn"
            variant={action === 'delete' ? 'secondary' : 'primary'}
            contextVariant={action === 'delete' && 'danger'}
            onClick={handleSubmission}
          >
            {t(
              `submission_and_return.tab_submission.modal_actions.${
                action === 'delete' ? 'button_delete' : 'button_next'
              }`
            )}
          </Button>
        </S.FooterWrapper>
      </S.ContentWrapper>
    </Modal>
  );
};

export default withAppContext(ModalActions);
