import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  submissions: [],
  returns: [],
  bills: [],
  filters: {
    fileName: null,
    title: null,
  },
  paginate: {
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
  },
  isLoading: false,
  sendingSubmission: false,
  error: null,
  currentSubmission: null,
};

const fetchRequestSubmissions = (state) => ({ ...state, isLoading: true });

export const fetchSuccessSubmissions = (state, action) => ({
  ...state,
  submissions:
    action.activePage > 1
      ? [...state.submissions, ...action.data]
      : action.data,
  filters: action.filters,
  paginate: {
    activePage: action.activePage,
    itemsCountPerPage: action.itemsCountPerPage,
    totalItemsCount: action.totalItemsCount,
  },
  isLoading: false,
});

const fetchRequestNewSubmission = (state) => ({
  ...state,
  sendingSubmission: true,
  error: null,
});

const fetchSuccessNewSubmission = (state) => ({
  ...state,
  sendingSubmission: false,
});

const fetchSubmissionDetails = (state) => ({
  ...state,
  isLoading: true,
  currentSubmission: null,
});

const fetchSuccessSubmissionDetails = (state, action) => ({
  ...state,
  isLoading: false,
  currentSubmission: action.data,
});

const fetchRequestReturns = (state) => ({ ...state, isLoading: true });

export const fetchSuccessReturns = (state, action) => ({
  ...state,
  returns:
    action.activePage > 1 ? [...state.returns, ...action.data] : action.data,
  filters: action.filters,
  paginate: {
    activePage: action.activePage,
    itemsCountPerPage: action.itemsCountPerPage,
    totalItemsCount: action.totalItemsCount,
  },
  isLoading: false,
});

const fetchRequestBills = (state) => ({ ...state, isLoading: true });

export const fetchSuccessBills = (state, action) => ({
  ...state,
  bills: action.activePage > 1 ? [...state.bills, ...action.data] : action.data,
  filters: action.filters,
  paginate: {
    activePage: action.activePage,
    itemsCountPerPage: action.itemsCountPerPage,
    totalItemsCount: action.totalItemsCount,
  },
  isLoading: false,
});

export const fetchError = (state, action) => ({
  ...state,
  isLoading: false,
  sendingSubmission: false,
  error: action.error,
});

export const clearError = (state) => ({
  ...state,
  error: null,
});

export const clearFiltersAndPaginate = (state) => ({
  ...state,
  filters: {
    fileName: null,
    title: null,
  },
  paginate: {
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
  },
  error: null,
});

const buildToastSuccess = (state) => ({ ...state });

const HANDLERS = {
  [actions.FETCH_REQUEST_SUBMISSIONS]: fetchRequestSubmissions,
  [actions.FETCH_SUCCESS_SUBMISSIONS]: fetchSuccessSubmissions,
  [actions.FETCH_REQUEST_NEW_SUBMISSION]: fetchRequestNewSubmission,
  [actions.FETCH_SUCCESS_NEW_SUBMISSION]: fetchSuccessNewSubmission,
  [actions.FETCH_REQUEST_SUBMISSION_DETAILS]: fetchSubmissionDetails,
  [actions.FETCH_SUCCESS_SUBMISSION_DETAILS]: fetchSuccessSubmissionDetails,
  [actions.FETCH_REQUEST_APPROVE_SUBMISSION]: clearError,
  [actions.FETCH_REQUEST_DELETE_SUBMISSION]: clearError,
  [actions.FETCH_REQUEST_RETURNS]: fetchRequestReturns,
  [actions.FETCH_SUCCESS_RETURNS]: fetchSuccessReturns,
  [actions.FETCH_REQUEST_EXPORT_RETURN]: clearError,
  [actions.FETCH_REQUEST_BILLS]: fetchRequestBills,
  [actions.FETCH_SUCCESS_BILLS]: fetchSuccessBills,
  [actions.FETCH_ERROR]: fetchError,
  [actions.CLEAR_ERROR]: clearError,
  [actions.CLEAR_FILTERS_AND_PAGINATE]: clearFiltersAndPaginate,
  [actions.BUILD_TOAST_SUCCESS]: buildToastSuccess,
};

const submissionAndReturn = createModuleReducer(INITIAL_STATE, HANDLERS);

export default submissionAndReturn;
