import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import Field from 'components/Form/Field';

export type FileUploadProps = {
  disabled: boolean;
};

export const FormContainer = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    .Field {
      padding-left: 0px;
    }
    .FormFieldset {
      @media (min-width: ${breakpoints.desktopXL}) {
        margin-left: 25%;
      }
    }
  `}
`;

export const WrapperField = styled(Field)`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.lg} !important;
  `}
`;

export const FileUploadContainer = styled.div<FileUploadProps>`
  ${({ disabled }) => css`
    .Dropdown {
      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.4;
      `}
    }
  `}
`;
