import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
`;

export const FooterWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${space.sm};
  `}
`;
