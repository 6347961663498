const actions = {
  FETCH_REQUEST_SUBMISSIONS: 'FETCH_REQUEST_SUBMISSIONS/submissionAndReturn',
  FETCH_SUCCESS_SUBMISSIONS: 'FETCH_SUCCESS_SUBMISSIONS/submissionAndReturn',
  FETCH_REQUEST_NEW_SUBMISSION:
    'FETCH_REQUEST_NEW_SUBMISSION/submissionAndReturn',
  FETCH_SUCCESS_NEW_SUBMISSION:
    'FETCH_SUCCESS_NEW_SUBMISSION/submissionAndReturn',
  FETCH_REQUEST_SUBMISSION_DETAILS:
    'FETCH_REQUEST_SUBMISSION_DETAILS/submissionAndReturn',
  FETCH_SUCCESS_SUBMISSION_DETAILS:
    'FETCH_SUCCESS_SUBMISSION_DETAILS/submissionAndReturn',
  FETCH_REQUEST_APPROVE_SUBMISSION:
    'FETCH_REQUEST_APPROVE_SUBMISSION/submissionAndReturn',
  FETCH_SUCCESS_APPROVE_SUBMISSION:
    'FETCH_SUCCESS_APPROVE_SUBMISSION/submissionAndReturn',
  FETCH_REQUEST_DELETE_SUBMISSION:
    'FETCH_REQUEST_DELETE_SUBMISSION/submissionAndReturn',
  FETCH_SUCCESS_DELETE_SUBMISSION:
    'FETCH_SUCCESS_DELETE_SUBMISSION/submissionAndReturn',
  FETCH_REQUEST_RETURNS: 'FETCH_REQUEST_RETURNS/submissionAndReturn',
  FETCH_SUCCESS_RETURNS: 'FETCH_SUCCESS_RETURNS/submissionAndReturn',
  FETCH_REQUEST_EXPORT_RETURN:
    'FETCH_REQUEST_EXPORT_RETURN/submissionAndReturn',
  FETCH_SUCCESS_EXPORT_RETURN:
    'FETCH_SUCCESS_EXPORT_RETURN/submissionAndReturn',
  FETCH_REQUEST_BILLS: 'FETCH_REQUEST_BILLS/submissionAndReturn',
  FETCH_SUCCESS_BILLS: 'FETCH_SUCCESS_BILLS/submissionAndReturn',
  FETCH_ERROR: 'FETCH_ERROR/submissionAndReturn',
  CLEAR_ERROR: 'CLEAR_ERROR/submissionAndReturn',
  CLEAR_FILTERS_AND_PAGINATE: 'CLEAR_FILTERS_AND_PAGINATE/submissionAndReturn',
  BUILD_TOAST: 'BUILD_TOAST/submissionAndReturn',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/submissionAndReturn',

  fetchSubmissions: (filters, page) => ({
    type: actions.FETCH_REQUEST_SUBMISSIONS,
    filters,
    page,
  }),

  fetchNewSubmission: (data) => ({
    type: actions.FETCH_REQUEST_NEW_SUBMISSION,
    data,
  }),

  fetchSubmissionDetails: (id) => ({
    type: actions.FETCH_REQUEST_SUBMISSION_DETAILS,
    id,
  }),

  fetchApproveSubmission: (id) => ({
    type: actions.FETCH_REQUEST_APPROVE_SUBMISSION,
    id,
  }),

  fetchDeleteSubmission: (id) => ({
    type: actions.FETCH_REQUEST_DELETE_SUBMISSION,
    id,
  }),

  fetchReturns: (filters, page) => ({
    type: actions.FETCH_REQUEST_RETURNS,
    filters,
    page,
  }),

  fetchBills: (filters, page) => ({
    type: actions.FETCH_REQUEST_BILLS,
    filters,
    page,
  }),

  fetchExportReturn: () => ({
    type: actions.FETCH_REQUEST_EXPORT_RETURN,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),

  clearFiltersAndPaginate: () => ({
    type: actions.CLEAR_FILTERS_AND_PAGINATE,
  }),
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
};

export default actions;
