import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  FlexGrid,
  FlexGridItem,
  Text,
  Alert,
} from '@agendaedu/ae-web-components';

import FormFieldset from 'components/Form/Fieldset';
import FileField from 'components/Form/FileField';

import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import walletsActions from 'store/edupay/wallets/actions';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import * as S from './styles';
import { SubmissionFormProps } from './types';

const ONE_MB_VALUE_IN_KB = 1024;
const LIMIT_SIZE_10_MB = 10 * Math.pow(ONE_MB_VALUE_IN_KB, 2);

const SubmissionForm = ({
  formContext: { updateAttribute, form, changeMeta, hasErrorOnAttribute },
}: SubmissionFormProps) => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { file, recipient_wallet_id: recipientWalletId } = form;

  const [currentRecipientWalletId, setCurrentRecipientWalletId] =
    useState(recipientWalletId);

  const { walletsOptions } = useSelector((state) => state.wallets);
  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;

  const { error } = useSelector((state) => state.submissionAndReturn);
  const { clearError } = submissionAndReturnActions;

  const getErrorMessage = () => {
    const detail = error[0]?.detail || '';

    if (detail.includes('already imported')) {
      return t(
        'submission_and_return.new_submission.new_submission_form.error_file_already_exists'
      );
    }

    return t(
      'submission_and_return.new_submission.new_submission_form.error_importing_file'
    );
  };

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  useEffect(() => {
    if (!file?.length && !!error) dispatch(clearError());

    if (hasErrorOnAttribute('file'))
      return changeMeta('nextStepDisabled', true);
  }, [file]);

  useEffect(() => {
    updateAttribute(
      'recipient_wallet_id',
      currentRecipientWalletId !== null ? currentRecipientWalletId : null
    );
  }, [currentRecipientWalletId, updateAttribute]);

  return (
    <S.FormContainer>
      <FormFieldset>
        <Text variant="title-bold-20" mb={32} color="neutral.black">
          {t('submission_and_return.new_submission.new_submission_form.title')}
        </Text>

        <FlexGrid>
          <FlexGridItem cols={{ desktopLG: 8 }}>
            <S.WrapperField
              fullWidth
              withArrowSelect
              label={t(
                'submission_and_return.new_submission.new_submission_form.wallet_label'
              )}
              type="selectWithIcon"
              attributeName="recipient_wallet_id"
              classNamePrefix="wallet_configuration"
              options={walletsOptions.filter(
                (option) => !option.label?.includes('Mensalidade Garantida')
              )}
              value={Number(currentRecipientWalletId)}
              onChange={(e) => {
                setCurrentRecipientWalletId(e.value?.toString());
              }}
            />

            <Text
              variant="body-regular-14"
              color="neutral.black"
              lineHeight="lg"
            >
              {t(
                'submission_and_return.new_submission.new_submission_form.description'
              )}
            </Text>

            <Text variant="label-regular-14" color="neutral.gray1" mb={-4}>
              {t(
                'submission_and_return.new_submission.new_submission_form.file_label'
              )}
            </Text>
            <S.FileUploadContainer disabled={file && file.length > 0}>
              <FileField
                attributeName="file"
                hideDefaultLabel={true}
                hideTooltip={true}
                acceptTypes="any"
                maxSize={LIMIT_SIZE_10_MB}
                buttonText={t(
                  'submission_and_return.new_submission.new_submission_form.file_placeholder'
                )}
                multiple={false}
              />
            </S.FileUploadContainer>

            {!!error && (
              <Text
                variant="label-regular-14"
                color="context.danger.default"
                mt={-4}
              >
                *{getErrorMessage()}
              </Text>
            )}

            {!!file?.length && !error && (
              <Alert variant="informative" mt={16}>
                {t(
                  'submission_and_return.new_submission.new_submission_form.alert_message'
                )}
              </Alert>
            )}
          </FlexGridItem>
        </FlexGrid>
      </FormFieldset>
    </S.FormContainer>
  );
};

export default tabifyForWizard({
  title: '',
  subTitle: '',
  validations: [],
})(withFormContext(SubmissionForm));
