import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import PageContainer from 'components/PageContainer';

export const SubmissionAndReturnWrapper = styled(PageContainer)`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    min-height: 100vh;
    margin: ${space.xs4};
    padding: ${space.sm} ${space.xl3} ${space.xl2} ${space.xl3};

    i {
      color: ${colors.brand.primary.default};
    }

    .container-header {
      flex-direction: column !important;
      align-items: flex-start !important;

      .container-title {
        margin-bottom: ${space.xl};

        .PageTitle {
          margin-top: 0;
        }
      }
    }

    .container-content {
      padding: ${space.xs4};
      border-radius: ${border.radius.md};

      .main-content {
        padding: ${space.xs4};

        .Tabs.default {
          .tabs-header {
            .tab-list {
              .react-tabs__tab-list {
                margin: ${space.xs4};

                .react-tabs__tab {
                  padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};
                  margin: ${space.xs4};
                  height: 52px;

                  position: relative;
                }

                .selected-tab {
                  &::after {
                    content: '';
                    position: absolute;
                    left: ${space.xs4};
                    right: ${space.xs4};
                    width: 100%;
                    height: 4px;
                    border-radius: ${space.xs3} ${space.xs3} ${space.xs4}
                      ${space.xs4};
                  }
                }
              }
            }
          }

          .react-tabs__tab-panel {
            margin: ${space.xl2};
          }
        }
      }
    }
  `}
`;
