import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SubmissionAndReturnContainer from 'components/Payments/SubmissionAndReturnContainer';
import NewSubmission from 'components/Payments/SubmissionAndReturnContainer/SubmissionTab/NewSubmission';
import SubmissionDetails from 'components/Payments/SubmissionAndReturnContainer/SubmissionTab/SubmissionDetails';

import reduxProvider from 'core/hoc/reduxProvider';

import walletsActions from 'store/edupay/wallets/actions';

const CnabImportRoutes = () => {
  const dispatch = useDispatch();

  const { wallets, currentWallet } = useSelector((state) => state.wallets);
  const { fetchRecipientsWalletsRequest } = walletsActions;

  useEffect(() => {
    if (wallets.length < 1 && !currentWallet) {
      dispatch(fetchRecipientsWalletsRequest());
    }
  }, []);

  return (
    <div className="CnabImportRoutes">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`/schools/imports/cnab`}
            component={SubmissionAndReturnContainer}
          />
          <Route
            exact
            path={`/schools/imports/cnab/new`}
            component={NewSubmission}
          />
          <Route
            exact
            path={`/schools/imports/cnab/:id`}
            component={SubmissionDetails}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(CnabImportRoutes);
